@import '_normalize';
@import '_variables';
@import '_base';
@import '_menu';
@import '_icons';
@import '_owl.carousel';
//@import '_owl.theme';
//@import '_owl.transitions';
.hosting {
    position: absolute;
    left: 40px;
    top: 18px;
    z-index: 5;
    font-family: $font-family-second;
    font-size: $text-signature;
    max-width: 50%;
    transition: $transition-fast;
    a {
        font-family: $font-family-second;
        letter-spacing: 1px;
    }
}
.logo {
    width: 225px;
    margin: 13px auto 0;
    display: block;
    z-index: 4;
    position: relative;
}
.openarms-logo {
    width: 10rem;
    margin-top: 25px;   
}
.openarms-logo:hover{
        opacity: 0.8;
    }
.slider {
    margin-top: -80px;
    margin-bottom: 5px;
    position: relative;
}
.socials-slider {
    position: absolute;
    top: 100%;
    margin-top: -110px;
    left: -110px;  
    transform: rotate(270deg);
    transition: $transition-slow;
    font-family: $font-family-second;
    a {
        font-size: 1.2rem;
        letter-spacing: 1px;
    }   
}
.social-separator {
    vertical-align: bottom;
    font-size: 30px;
} 
.diamond-img {
    max-width: 90%;
    width: 400px;
}

.footer-home-icon {
    margin-bottom: -40px;
    margin-top: 85px;
}
.instagram-center {
    width: 960px;
    max-width: 90%;
    margin: 0 auto;
    text-align: center; 
    padding-top: 6rem;
}
.instagram {
    margin-top: 60px;
    a {
        position: relative;
        overflow: visible;
    }
}
.instagram a:after {
    content:attr(data-title);
    position: absolute;
    transition: $transition-slow;
    opacity: 0;
    left: 0;
    top:1.5px;
    width:100%;
    min-height: 100%;
    padding:20px 10px;
    color:$color-blue;
    background-color: rgba(199, 183, 155, 0.9);
    line-height: 1.3em;

}
.instagram-images {
    display: inline-block;
    width: 33.3%;
    vertical-align: top;
    transition: $transition-fast;

}
.instagram a:hover:after {
    content:attr(data-title);
    height: auto;
    z-index: 5;
    opacity: 1;
}
@media (max-width:380px) {

    .instagram-center {
        h2 {
            font-size: 2rem;
        }
        .instagram-images {
            width: 100%;
        }
    }
}
@media (max-width:60rem) {
    .socials-slider {
        margin-top: 0;
        left: 0.5rem;  
        top: 98%;
        transform: rotate(360deg);
        transition: $transition-slow;
    }
    .socials-slider a {
        font-size: 1rem;
    }
}
@media (max-width:640px) {
    .hosting {
        position: relative;
        left: 25%;
        text-align: center;
        margin-bottom: 25px;
    }
    .logo {
        width: 215px;
        max-width: 100%;
    }
    .socials-slider {
        display: none;
    }
    .footer-home-icon {
        margin-top: 40px;
        margin-bottom: -60px;
    }
    .instagram-images {
        width: 50%;
    }
}
@media (max-width:24rem) {
    .slider {
        margin-top: -68px;
    }
}