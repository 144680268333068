// Base
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// The base font-size is set at 62.5% for having the convenience
// of sizing rems in a way that is similar to using px. 1.6rem=16px

html {
    box-sizing: border-box;
    font-size: 62.5%;
}

// Default body styles
body {
    color: $color-blue;
    background-color: $color-brown;
    font-size: 1.6em; // currently ems cause chrome bug misinterpreting rems on body element
    line-height: 1;
    font-family: $font-family;
    font-style: normal;
}
// Set box-sizing globally to handle padding and border widths
*,
*:after,
*:before {
    box-sizing: inherit;
    font-weight: 400;
    font-variant-numeric: lining-nums;
    -moz-font-feature-settings: "lnum";
    -webkit-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
}
a {
    color: $color-blue;
    text-decoration: none;
    outline: 0;

}
p {
    font-size: $text-paragraph;
    margin: 0px auto 25px;
    transition: $transition-slow;
    line-height: 2.8rem;
    a {
        border-bottom: solid 2px;
    }
}
br {
    line-height: 1rem;
}
.container-center {
    width: 100%;
    border-right: 4rem solid $color-brown;
    border-left: 4rem solid $color-brown;

}
.text-center {
    width: 606px;
    max-width: 95%;
    text-align: center;
    margin: 0 auto;
}
.title {
    font-size: $text-title;
    letter-spacing: -0.2rem;
    line-height: 0.9;
    margin: 6rem auto 3.9rem;
    width: 700px;
    max-width: 95%;
    transition: $transition-slow;

}
.subtitle,
h2 {
    font-size: $text-subtitle;
    margin: 10px auto 20px;
    transition: $transition-slow;
    line-height: 6rem;

}
.color-brown {
    color: $color-brown;
}
.color-blue {
    color: $color-blue;
}
.color-orange {
    color: $color-orange;
}
.color-green {
    color: $color-green;
}
.color-yellow {
    color: $color-yellow;
}
.background-blue {
    background-color: $color-blue;
    color: $color-brown;
    padding: 25px 0 95px;
    margin: 10px auto;
    position: relative;
    a {
        color: $color-brown;
    }
}
.space-top {
    margin-top: 115px;
}
.big-icon {
    font-size: $big-icon-size;
    display: block;
    text-align: center;
    margin: 10px auto 20px;
    line-height: 120px;
}
.medium-icon {
    font-size: 120px;
    line-height: 100px;
    display: block;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 40px;
}
.small-icon {
    font-size: 3.7rem;
    display: block;
    text-align: center;
}
.left-icon {
    position: absolute;
    top: 50%;
    left: -3.9rem;
    margin-top: -1.75rem;
}
.right-icon {
    position: absolute;
    top: 50%;
    margin-top: -1.75rem;
    right: -3.9rem;    
}
.btn {
    border: 2.7px solid;
    padding: 9px 35px;
    display: block;
    width: 300px;
    max-width: 90%;
    margin: 2rem auto 0;
    text-transform: uppercase;
    font-family: $font-family-second;
    font-size: 12px;
    transition: $transition-slow;
    letter-spacing: 1px;
    &:focus,
    &:hover {
        background-color: $color-brown;
        border-color: $color-brown;
        color: $color-blue;
        outline: 0;
        transition: $transition-slow;

    }
}
.btn.background-brown {
    &:focus,
    &:hover {
        background-color: $color-blue;
        border-color: $color-blue;
        color: $color-brown;
        outline: 0;
    }
}
.signature {
    margin: 7rem auto;
    p {
        font-size: $text-signature;
    }
}

@media (max-width:60rem) {
    .title {
        font-size: $small-text-title;
        letter-spacing: -0.1rem;
        line-height: 0.9;
        margin-bottom: 40px;
        width: 100%;
        transition: $transition-slow;
    }
    .subtitle {
        font-size: $small-text-subtitle;
        transition: $transition-slow;
        line-height: $small-text-subtitle;
        margin-bottom: 15px;
    }
    p {
        font-size: $small-text-paragraph;
        transition: $transition-slow;
        margin-bottom: 15px;
    }
    .big-icon {
        font-size: $small-big-icon-size;
    }
} 
@media (max-width:645px) {
    .title {
        font-size: $x-small-text-title;
        transition: $transition-slow;
        margin-top: 1rem;
    }
    .subtitle, h2 {
        font-size: $x-small-text-subtitle;
        transition: $transition-slow;
        line-height: $x-small-text-subtitle;
    }
    a.subtitle {
        font-size: 2.3rem;
    }
    .space-top {
        margin-top: 75px;
    }
    .small-icon {
        font-size: 2.2rem;
    }
    .container-center {
        border-right: 2.3rem solid $color-brown;
        border-left: 2.3rem solid $color-brown;
    }
    .left-icon {
        left: -2.2rem;
    }
    .right-icon {
        right: -2.2rem;    
    }
}