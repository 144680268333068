$icomoon-font-path: "../fonts" !default;

$icon-right: "\e900";
$icon-left: "\e901";
$icon-start: "\e902";
$icon-double-start: "\e903";
$icon-heart: "\e904";
$icon-double-heart: "\e905";
$icon-circle: "\e906";
$icon-diamond: "\e907";
$icon-double-circle: "\e908";
$icon-double-diamond: "\e909";

