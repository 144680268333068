
// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-brown: #c7b79b;
$color-blue: #023bfd;
$color-orange: #fd5b00;
$color-green: #00f613;
$color-yellow: #ffff01;

//Sizes
//__________________________________________________

$text-title: 9.8rem;
$text-subtitle: 6.6rem;
$text-paragraph: 2.2rem;
$text-signature: 1.1rem;
$big-icon-size: 220px;
//small
$small-text-title: 4.9rem;
$small-text-subtitle: 3.3rem;
$small-text-paragraph: 2rem;
$small-big-icon-size: 150px;
//x-small
$x-small-text-title: 3.9rem;
$x-small-text-subtitle: 3rem;

//Font-family
//_____________________________________________

$font-family: maiola, serif;
$font-family-second: 'Montserrat', sans-serif;

//Transitions
//___________________________________
$transition-slow: 0.9s;
$transition-fast: 0.3s; 