@import "icons-variables";

@font-face {
  font-family: 'baulweekend';
  src:  url('#{$icomoon-font-path}/baulweekend.eot?uoahmk');
  src:  url('#{$icomoon-font-path}/baulweekend.eot?uoahmk#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/baulweekend.ttf?uoahmk') format('truetype'),
    url('#{$icomoon-font-path}/baulweekend.woff?uoahmk') format('woff'),
    url('#{$icomoon-font-path}/baulweekend.svg?uoahmk#baulweekend') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'baulweekend' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-right {
  &:before {
    content: $icon-right;
  }
}
.icon-left {
  &:before {
    content: $icon-left;
  }
}
.icon-start {
  &:before {
    content: $icon-start;
  }
}
.icon-contacto {
  &:before {
    content: $icon-double-start;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-como-ayudar {
  &:before {
    content: $icon-double-heart;
  }
}
.icon-circle {
  &:before {
    content: $icon-circle;
  }
}
.icon-diamond {
  &:before {
    content: $icon-diamond;
  }
}
.icon-quienes-somos {
  &:before {
    content: $icon-double-circle;
  }
}
.icon-home {
  &:before {
    content: $icon-double-diamond;
  }
}

