.menu {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    padding-top: 186px;
    height: 80px;
}
.is-active.menu {
    overflow: visible;
    min-height: 100%;
    z-index: 3;
}
.container {
    transition: opacity $transition-fast ease 0.6s;
    margin-top: -80px;
    padding-top: 80px;
}
.is-active + .container {
    position: absolute;
    height: 88%;
    overflow: hidden;
    top: 170px;
    left: 0;
    z-index: -1;
    width: 100% !important;
    opacity: 0;
    transition: opacity $transition-fast ease 0s;

}
.nav-link {
    display: block;
    line-height: 4rem;
    font-size: 54px;
    margin-top: 50px;
}
nav {
    visibility: hidden;
    z-index: -2;
    transition: all $transition-slow linear 0.3s;
    opacity: 0
}
.is-active nav {
    visibility: visible;
    z-index: 5;
    opacity: 1;
    transition: 0s;
}
.menu-container-icon {
    display: block;
    position: absolute;
    top: 2.8rem;
    width: 3.3rem;
    z-index: 5;
    right: 4.0rem;
    height: 25px;
    cursor: pointer;
}
.menu-icon {
    background: $color-blue;
    width: 100%;
    height: 2px;
    position: relative;
    margin-top: 12px;
    transition: background .5s, transform .3s linear .2s, top .3s;

}
.menu-icon-bar {
    background: $color-blue;
    width: 100%;
    height: 2px;
    position: relative;
    margin-top: 12px;
    transition: background .5s, transform .3s linear .2s, top .3s;
}
.menu-icon:after,
.menu-icon:before {
    content: '';
    background: inherit;
    position: absolute;
    left: 0;
    top: -.7rem;
    width: 100%;
    height: 100%;
    transform: rotate(0deg);
    transition: background .5s, transform .3s linear .2s, top .3s;

}
.menu-icon::after {
    top: .7rem;
}
 .is-active .menu-icon {
    background: rgba(0,0,0,0);
    z-index: 1;
}
.is-active .menu-icon-bar {
    background: rgba(0,0,0,0);
    margin-top: 0;
}
 .is-active .menu-icon::before {
    top: 0;
    background: $color-blue;
    transform: rotatez(45deg);
    transform-origin: center center 0;
}
 .is-active .menu-icon::after {
    top: 0;  
    background: $color-blue;
    transform: rotatez(-45deg);
    transform-origin: center center 0;
}
.nav-quienes-somos {
    color: $color-orange;
}
.nav-como-ayudar {
    color: $color-green;
}
.nav-contacto {
    color: $color-yellow;
}
.nav-icon {
    display: block;
    margin-top: 40px;
}
.nav-selected span {
    border-bottom: solid 3px;
}
.socials-menu {
    margin: 9rem auto 1rem;
}
.socials-menu a {
    text-decoration: none;
    color: $color-blue;
    font-family: $font-family-second;
    font-size: $text-signature;
    letter-spacing: 1px;
}
@media (max-width:60rem) {
    .menu {
        padding-top:155px;
    }
}
@media (max-width:645px) {
    .menu-container-icon {
        top: 1rem;
        right: 2rem;
    }
    .menu {
        padding-top: 175px;
    }
    .nav-link {
        line-height: 1;
        font-size: 30px;
        margin-top: 0;

    }
    .nav-icon {
        margin-top: 25px;
    }
    .socials-menu {
        margin-top: 4rem;
    }
}
@media (max-width:15rem) {
    .menu {
        padding-top: 185px;
    }
}